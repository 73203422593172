<template>
    <div class="main-wrapper">
      <div style="text-align: right">
        <el-button type="primary" @click="toAdd"><i class="el-icon-plus"></i> 添加客服数据</el-button>
      </div>
      <el-table :data="serviceList" class="customTable" style="width: 100%; margin-top: 15px; flex: 1" height="1%" :cell-style="{height:'70px'}">
        <el-table-column prop="name" label="客服名称" min-width="150"></el-table-column>
        <el-table-column prop="c_name" label="行业分类" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-link type="success" @click="editService(scope.row.kefu_id)">编辑</el-link>
            <el-link type="danger" @click="deleteService(scope.row)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pages-center" style="margin: 20px 0 30px"
                     :current-page="listPages.currentPageNum"
                     :page-size="listPages.eachPageNum"
                     layout="prev, pager, next, jumper"
                     :total="listPages.total"
                     @current-change="pagesCurrentChange">
      </el-pagination>
    </div>
</template>

<script>
import {kefuListKefuData, keFuDelKeFuData} from '@/utils/apis'
export default {
  name: "CustomerServiceManagement",
  data(){
    return {
      serviceList:[],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.getServiceList();
  },
  methods:{
    getServiceList(){
      let params = {
        type:'page',
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      kefuListKefuData(params).then((res)=>{
        this.serviceList = res.data.data;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    toAdd(){
      this.$router.push('/practice/addCustomerServiceManagement')
    },
    editService(id){
      this.$router.push({
        path:'/practice/addCustomerServiceManagement',
        query:{
          id:id
        }
      })
    },
    deleteService(row){
      this.$confirm(`此操作将永久删除该试卷，是否继续`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        let params = {
          kefu_id:row.kefu_id
        }
        keFuDelKeFuData(params).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getServiceList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    pagesCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getServiceList();
    }
  }
}
</script>

<style scoped lang="scss">
.main-wrapper {
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
</style>